"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault")["default"];
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createArtworkContext = createArtworkContext;
exports.getConfiguration = getConfiguration;
exports.getDataAndInitialConfiguration = getDataAndInitialConfiguration;
exports.getInitialConfiguration = getInitialConfiguration;
exports.getIsAuthorOrAdmin = getIsAuthorOrAdmin;
exports.setConfiguration = setConfiguration;
exports.setData = setData;
exports.translate = translate;
var _objectSpread2 = _interopRequireDefault(require("@babel/runtime/helpers/objectSpread2"));
var _getUtils = _interopRequireDefault(require("wapplr-react/dist/common/Wapp/getUtils"));
var _utils = require("wapplr/dist/common/utils");
function translate(_ref) {
  var text = _ref.text,
    _ref$selectedLang = _ref.selectedLang,
    selectedLang = _ref$selectedLang === void 0 ? "lang_1" : _ref$selectedLang;
  if (typeof text == "string") {
    return text;
  }
  if (typeof text == "object" && text[selectedLang]) {
    return text[selectedLang];
  }
  if (typeof text == "object") {
    return text[Object.keys(text)[0]] || "";
  }
  return "";
}
function setConfiguration(_ref2) {
  var newConfiguration = _ref2.newConfiguration,
    configuration = _ref2.configuration,
    data = _ref2.data,
    isAuthorOrAdmin = _ref2.isAuthorOrAdmin,
    onChange = _ref2.onChange,
    storage = _ref2.storage,
    externalContext = _ref2.externalContext,
    context = _ref2.context,
    appContext = _ref2.appContext,
    force = _ref2.force;
  Object.keys(configuration).forEach(function (key) {
    if (typeof newConfiguration[key] === 'undefined') {
      newConfiguration[key] = configuration[key];
    }
  });
  var validNewConfiguration = getConfiguration({
    data: data,
    savedConfiguration: newConfiguration,
    isAuthorOrAdmin: isAuthorOrAdmin,
    externalContext: externalContext,
    context: context,
    appContext: appContext
  });
  if (!validNewConfiguration.error) {
    try {
      if (JSON.stringify(validNewConfiguration) !== JSON.stringify(configuration) || force) {
        storage({
          configuration: validNewConfiguration
        });
        if (onChange) {
          onChange(JSON.parse(JSON.stringify(validNewConfiguration)));
        }
      } else {
        if (isAuthorOrAdmin) {
          console.log('[APP] It tried update the configuration but no changes...');
        }
      }
    } catch (e) {
      console.error({
        message: 'An error occurred while saving ...',
        code: 1004,
        originalError: e
      });
    }
  }
  if (validNewConfiguration.error) {
    if (validNewConfiguration.error.code < 1100) {
      if (isAuthorOrAdmin) {
        console.error(validNewConfiguration.error);
      }
    } else {
      console.warn(validNewConfiguration.error);
    }
  }
}
function getConfiguration() {
  var _data$settings, _data$settings$basic_;
  var p = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var data = p.data,
    _p$savedConfiguration = p.savedConfiguration,
    savedConfiguration = _p$savedConfiguration === void 0 ? {} : _p$savedConfiguration;
  var selectedLang = savedConfiguration.selectedLang;
  var languages = ((_data$settings = data.settings) === null || _data$settings === void 0 ? void 0 : (_data$settings$basic_ = _data$settings.basic_settings) === null || _data$settings$basic_ === void 0 ? void 0 : _data$settings$basic_.languages) || [];
  return {
    selectedLang: languages.indexOf(selectedLang) > -1 ? selectedLang : languages[0]
  };
}
function getInitialConfiguration(_ref3) {
  var data = _ref3.data,
    savedData = _ref3.savedData,
    isAuthorOrAdmin = _ref3.isAuthorOrAdmin,
    externalContext = _ref3.externalContext,
    context = _ref3.context,
    appContext = _ref3.appContext;
  var initialConfiguration;
  try {
    initialConfiguration = getConfiguration({
      data: data,
      isAuthorOrAdmin: isAuthorOrAdmin,
      externalContext: externalContext,
      context: context,
      appContext: appContext
    });
    if (initialConfiguration.error) {
      if (isAuthorOrAdmin) {
        console.error({
          message: "The artwork cannot be loaded",
          code: initialConfiguration.error.code,
          originalError: initialConfiguration.error
        });
      }
      initialConfiguration = null;
    }
  } catch (e) {
    if (isAuthorOrAdmin) {
      console.error({
        message: "The artwork cannot be loaded",
        code: 1003,
        originalError: e
      });
    }
  }
  if (savedData !== null && savedData !== void 0 && savedData.configuration) {
    try {
      var savedConfiguration = getConfiguration({
        data: data,
        savedConfiguration: savedData.configuration,
        isAuthorOrAdmin: isAuthorOrAdmin,
        context: context,
        appContext: appContext
      });
      if (savedConfiguration.error) {
        console.warn({
          message: 'The saved configuration cannot be loaded',
          code: savedConfiguration.error.code,
          originalError: savedConfiguration.error
        });
      }
      if (!savedConfiguration.error) {
        initialConfiguration = savedConfiguration;
      }
    } catch (e) {
      console.warn({
        message: 'The saved configuration cannot be loaded',
        code: 1104,
        originalError: e
      });
    }
  }
  return initialConfiguration;
}
function getDataAndInitialConfiguration() {
  var p = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var context = p.context,
    appContext = p.appContext,
    postContext = p.postContext,
    externalContext = p.externalContext;
  var storage = appContext.storage;
  var wapp = context.wapp,
    req = context.req;
  var isAuthorOrAdmin = getIsAuthorOrAdmin({
    context: context,
    appContext: appContext,
    postContext: postContext
  });
  var data = {};
  try {
    var _postContext$post;
    data = postContext.post && typeof postContext.post.content == "string" ? JSON.parse(postContext.post.content) : (_postContext$post = postContext.post) !== null && _postContext$post !== void 0 && _postContext$post.content ? postContext.post.content : {};
  } catch (e) {
    console.log(e);
  }
  var savedData = storage();
  var query = req.wappRequest.query;
  var utils = (0, _getUtils["default"])(context);
  var initialState = typeof window !== 'undefined' && window[wapp.config.appStateName] || {
    req: {
      timestamp: Date.now()
    }
  };
  var firstRender = utils.getGlobalState().req.timestamp === initialState.req.timestamp || wapp.target === 'node';
  if (query.configuration && firstRender) {
    try {
      var configuration = JSON.parse(query.configuration);
      savedData = storage((0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, Object.fromEntries(Object.keys(savedData).map(function (key) {
        return key === 'drawerOpen' ? [key, savedData[key]] : [key, undefined];
      }))), {}, {
        configuration: configuration
      }));
    } catch (e) {
      console.log(e);
    }
  }
  var initialConfiguration = p.initialConfiguration || getInitialConfiguration({
    data: data,
    savedData: savedData,
    isAuthorOrAdmin: isAuthorOrAdmin,
    externalContext: externalContext,
    context: context,
    appContext: appContext
  });
  return {
    data: data,
    initialConfiguration: initialConfiguration
  };
}
function getIsAuthorOrAdmin(_ref4) {
  var _post$_author;
  var appContext = _ref4.appContext,
    postContext = _ref4.postContext;
  var userStatusManager = appContext.userStatusManager;
  var user = postContext.user,
    post = postContext.post;
  var isAdmin = user && user[userStatusManager === null || userStatusManager === void 0 ? void 0 : userStatusManager._status_isFeatured];
  var isAuthor = (user === null || user === void 0 ? void 0 : user._id) && (user === null || user === void 0 ? void 0 : user._id) === (post === null || post === void 0 ? void 0 : post._author) || (user === null || user === void 0 ? void 0 : user._id) && (user === null || user === void 0 ? void 0 : user._id) === (post === null || post === void 0 ? void 0 : (_post$_author = post._author) === null || _post$_author === void 0 ? void 0 : _post$_author._id);
  return isAdmin || isAuthor;
}
function setData(_ref5) {
  var newData = _ref5.newData,
    data = _ref5.data,
    isAuthorOrAdmin = _ref5.isAuthorOrAdmin,
    onDataChange = _ref5.onDataChange,
    storage = _ref5.storage,
    force = _ref5.force;
  Object.keys(data).forEach(function (key) {
    if (typeof newData[key] === 'undefined') {
      newData[key] = data[key];
    }
  });
  try {
    if (JSON.stringify(newData) !== JSON.stringify(data) || force) {
      storage({
        data: newData
      });
      if (onDataChange) {
        onDataChange(JSON.parse(JSON.stringify(newData)));
      }
    } else {
      if (isAuthorOrAdmin) {
        console.log('[APP] It tried update the data but no changes...');
      }
    }
  } catch (e) {
    console.error({
      message: 'An error occurred while saving ...',
      code: 1004,
      originalError: e
    });
  }
}
var artworkContextObject = {};
function createArtworkContextChangeHandler(_ref6) {
  var artworkContext = _ref6.artworkContext,
    context = _ref6.context;
  artworkContext.onChange = function (configuration) {
    Object.keys(configuration).forEach(function (key) {
      artworkContext.configuration[key] = configuration[key];
    });
    context.res.wappResponse.store.dispatch(context.wapp.states.runAction('app', {
      type: 'INS_APP',
      name: 'configuration',
      value: artworkContext.configuration
    }));
  };
  artworkContext.onDataChange = function (data) {
    Object.keys(data).forEach(function (key) {
      artworkContext.data[key] = data[key];
    });
    context.res.wappResponse.store.dispatch(context.wapp.states.runAction('app', {
      type: 'INS_APP',
      name: 'data',
      value: artworkContext.data
    }));
  };
}
function createArtworkContext(_ref7) {
  var context = _ref7.context,
    appContext = _ref7.appContext,
    postContext = _ref7.postContext,
    externalContext = _ref7.externalContext,
    force = _ref7.force;
  if (artworkContextObject.current && context.wapp.target !== "node" && !force) {
    var artworkContext = artworkContextObject.current;
    createArtworkContextChangeHandler({
      artworkContext: artworkContext,
      context: context
    });
    return artworkContext;
  } else {
    artworkContextObject.current = {};
    var _artworkContext = artworkContextObject.current;
    var _getDataAndInitialCon = getDataAndInitialConfiguration({
        context: context,
        appContext: appContext,
        postContext: postContext,
        externalContext: externalContext
      }),
      initialConfiguration = _getDataAndInitialCon.initialConfiguration,
      data = _getDataAndInitialCon.data;
    _artworkContext.initialConfiguration = initialConfiguration || {};
    _artworkContext.configuration = (0, _utils.copyObject)(_artworkContext.initialConfiguration);
    _artworkContext.data = data;
    _artworkContext.isAuthorOrAdmin = getIsAuthorOrAdmin({
      context: context,
      appContext: appContext,
      postContext: postContext
    });
    _artworkContext.translate = function (text) {
      return translate({
        externalContext: externalContext,
        data: data,
        selectedLang: _artworkContext.configuration.selectedLang,
        text: text
      });
    };
    createArtworkContextChangeHandler({
      artworkContext: _artworkContext,
      context: context
    });
    _artworkContext.setConfiguration = function (newConfiguration) {
      return setConfiguration((0, _objectSpread2["default"])((0, _objectSpread2["default"])({
        newConfiguration: newConfiguration
      }, _artworkContext), {}, {
        storage: appContext.storage,
        externalContext: externalContext,
        context: context,
        appContext: appContext
      }));
    };
    _artworkContext.setData = function (newData, force) {
      return setData((0, _objectSpread2["default"])((0, _objectSpread2["default"])({
        newData: newData
      }, _artworkContext), {}, {
        storage: appContext.storage,
        force: force
      }));
    };
    return _artworkContext;
  }
}