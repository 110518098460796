"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var labels = {
  defaultAspectRatio: "Default aspect ratio",
  languages: {
    lang_1: "English"
  }
};
var _default = labels;
exports["default"] = _default;