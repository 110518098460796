"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var menus = {
  moreArtworksMenu: "More artworks",
  homeMenu: "Home"
};
var _default = menus;
exports["default"] = _default;