"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = makeMaterialStyle;
function makeMaterialStyle() {
  return {
    description: {},
    listItem: {},
    listItemIcon: {}
  };
}