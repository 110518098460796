"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var messages = {
  defaultDescription: ""
};
var _default = messages;
exports["default"] = _default;