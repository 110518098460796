"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var routes = {
  artworkRoute: "/artwork",
  descriptionRoute: "/concept",
  renderRoute: "/render",
  summaryRoute: "/summary",
  editorRoute: "/editor",
  moreArtworksRoute: "/artworks/categories/coded"
};
var _default = routes;
exports["default"] = _default;